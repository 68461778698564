import React from 'react'

export default function Fourofour() {
    return (
        <div className="mx-auto text-center">
            <img src="images/Spinner.gif" alt="spinner"/>
            
      
        </div>
    )
}
