import React from 'react'
import ShoppingList from '../components/ShoppingList'

export default function Landing() {
    return (
        <div>
            <ShoppingList/>
        </div>
    )
}
