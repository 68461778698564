import React from 'react'

export default function Carousel() {
    return (
        <div className="w3-animate-zoom">
            <div style={{backgroundImage: 'url(images/2.jpg)', height: '500px', backgroundSize: 'cover'}}  className="carousel slide">

        </div>
            </div>
     
        
    )
}
